import './index.scss';

import React, { useEffect, useState } from 'react';
import { MapContainer, Marker, TileLayer, useMap } from 'react-leaflet';
import { db } from '../../firebase';
import { GeoPoint, addDoc, collection } from 'firebase/firestore';

const Position = () => {
  const [address, setAddress] = useState("Prague, Tchéquie");
  const [coordinates, setCoordinates] = useState([50.55, 14.4378005]);
  const [city, setCity] = useState('Prague');
  const [country, setCountry] = useState('Tchéquie');
  const [latitude, setLatitude] = useState(50.55);
  const [longitude, setLongitude] = useState(14.4378005);

  useEffect(() => {
    setAddress(`${city}, ${country}`);
  }, [city, country]);

  const handleCoordinateChange = (lat, lng) => {
    const validLat = isNaN(lat) || lat === null ? 0 : lat;
    const validLng = isNaN(lng) || lng === null ? 0 : lng;

    setCoordinates([validLat, validLng]);
    setLatitude(validLat);
    setLongitude(validLng);
  };

  function FlyMapTo() {
    const map = useMap();

    useEffect(() => {
      map.flyTo(coordinates);
    }, [coordinates, map]);

    return null;
  }

  const submitPosition = async (e) => {
    e.preventDefault();
    const travel = {
      city,
      country,
      coordinates: new GeoPoint(latitude, longitude)
    };
    try {
      await addDoc(collection(db, 'travels'), travel);
      alert("New travel data sent to firebase:\n" + travel.city);
    } catch (error) {
      alert("Failed to add travel:\n" + error);
    }
  };

  return (
    <div className="position-container">
      <h1 className="new-position-title"> New Position </h1>
      <form onSubmit={submitPosition}>
        <div className="position-data">
          <div className="top-position-data">
            <div className="left-data">
              <label>City:
                <input type="text"
                       value={city}
                       onChange={(e) => setCity(e.target.value)} />
              </label>
              <label>Country:
                <input type="text"
                       value={country}
                       onChange={(e) => setCountry(e.target.value)} />
              </label>
            </div>
            <div className="right-data">
              <label>Latitude:
                <input type="number"
                       value={latitude}
                       onChange={(e) => handleCoordinateChange(e.target.value, longitude)} />
              </label>
              <label>Longitude:
                <input type="number"
                       value={longitude}
                       onChange={(e) => handleCoordinateChange(latitude, parseFloat(e.target.value))} />
              </label>
            </div>
          </div>
          <div className="bottom-position-data">
            <div>Address: {address}</div>
          </div>
        </div>

        <div className="map">
          <MapContainer center={coordinates} zoom={12}>
            <FlyMapTo />
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <Marker position={coordinates} />
          </MapContainer>
        </div>

        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default Position;
